// Components/Routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing from './Landing';
import AdminDashboard from './AdminDashboard';
import Admin from './Admin';
import Login from './Login';
import ImageGallery from './ImageGallery';
import Logout from './Logout';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            {/* if user is not auth redirect back to Login */}
            <Route path="/admins" element={<Admin />} />
            <Route path="/admindashboard" element={<AdminDashboard />} /> 
            <Route path="/gallery" element={<ImageGallery />} />
            <Route path="/logout" element={<Logout />} />

            {/* <Route path="*" element={<NotFound />} /> */}

        </Routes>
    );
};

export default AppRoutes;
