import React from "react";
import { Layout, Menu } from "antd";
import { HeaderApp } from "./Header";
const { Header, Content, } = Layout;

const Admin = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/login";
    }
  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <HeaderApp />
        </div>
      </Header>
      <Menu theme="dark" mode="horizontal" >
        <Menu.Item key="1"
        onClick={() => {
            window.location.href = "/admindashboard";
        }
        }
        >Dashboard</Menu.Item>
        <Menu.Item key="2"
        onClick={() => {
            window.location.href = "/gallery";
        }
        }
        >Gallery</Menu.Item>
        <Menu.Item key="3"
        onClick={() => {
            localStorage.removeItem("token");
            window.location.href = "/login";
        }
        }
        >Logout</Menu.Item>
        <Menu.Item key="4"
        onClick={() => {
            window.location.href = "/";
        }
        }
        >Back to Site</Menu.Item>

      </Menu>
      <Content
        style={{
          padding: "0 48px",
        }}
      >
      </Content>
    </Layout>
  );
    };
export default Admin;
