// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';

import Footer from './Components/Footer';
import AppRoutes from './Components/Routes';
// import { Space } from 'antd';
const { Content } = Layout;

const App = () => (
  <Router>
    <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Content style={{ flex: '1' }}>
        {/* <LogoHeader /> */}
        <AppRoutes /> 
      </Content>
      <Footer />
    </Layout>
  </Router>
);

export default App;
