import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";


const Login = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    //remove text from the input fields


    axios
      .post(`${process.env.REACT_APP_API_URL}/api/login/`, values)
      .then((response) => {
        message.success("Login successful");
          localStorage.setItem("token", response.data.token);
          window.location.href = "/admins";          
      })
      .catch((error) => {
        message.error("Login failed. Please check your credentials.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ maxWidth: "400px", margin: "auto", marginTop: "100px" }}>
      <Form onFinish={onFinish}>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
          //do not remember the username
          initialValue=""
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
          //do not remember the password
          initialValue=""
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
