import { CarryOutOutlined } from "@ant-design/icons";
import style from "../Styles/Header.module.css";
import { Space } from "antd";

export const HeaderApp = () => {
  return (
    <div>
      <span className={style.appName}>
        <Space size="middle" style={{ display: "flex" }}>
          <CarryOutOutlined /> Fine Finish Painting Admin Dashboard
        </Space>
      </span>
    </div>
  );
};
