import React, { useState, useEffect } from "react";
import {
  Input,
  Layout,
  Form,
  theme,
  Button,
  Divider,
  Row,
  Col,
  message,
  Image,
  Modal,
} from "antd";
import postLogo from "../assets/room.jpeg";
import logoImage from "../assets/logo.png";
import external from "../assets/external.webp";
import internal from "../assets/internal.webp";
import internal_2 from "../assets/internal_2.webp";
import tinycolor from "tinycolor2"; // Import tinycolor2
const {Content } = Layout;

const colorBgContainer = "#ffffff"; // Replace with your actual background color
const backgroundColor = tinycolor(colorBgContainer);
const textColor = backgroundColor.isLight() ? "#ffffff" : "#33333"; // Dynamic text color for improved readability
const fontSizeMedium = "1.6rem"; // Slightly increased font size for better legibility
const lineHeight = "1.5"; // Increased line height for better readability



const App = () => {
   const [galleryImages, setGalleryImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_URL}/api/gallery`, {
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      setGalleryImages(data || []);
    })
    .catch((error) => {
      console.error("Error fetching images:", error);
    });
}, []);

     const goToPrevious = () => {
       setCurrentIndex((prevIndex) =>
         prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
       );
     };

     const goToNext = () => {
       setCurrentIndex((prevIndex) =>
         prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
       );
     };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
    
  const onFinish = (values) => {
    console.log("Success:", values);
    message.success("Your message has been sent!");
    setIsModalVisible(false);
    fetch(`${process.env.REACT_APP_API_URL}/api/contacts/`, {
      //get data from form and send to backend
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
    setTimeout(() => {
      window.location.reload();
    }
    , 2000);
    
     
    };
  
  
  const showModal = () => {
    setIsModalVisible(true);
  }

  


  
    

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        <Content style={{ margin: "0 16px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 500,
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: `url(${postLogo})`, // Set the background image
                backgroundSize: "cover", // Adjust as needed
                backgroundPosition: "center", // Adjust as needed
                borderRadius: borderRadiusLG,
                filter: "blur(2px)", // Adjust the blur intensity as needed
              }}
            />

            {/* Dark overlay */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for darkness
                borderRadius: borderRadiusLG,
              }}
            />

            <img
              src={logoImage}
              alt="Logo"
              style={{
                position: "absolute",
                top: 10,
                // left: 20,
                width: "300px", // Adjust the width as needed
                height: "auto",
                zIndex: 1, // Ensure the logo is on top
              }}
            />

            <div
              style={{
                color: textColor, // Set text color dynamically
                textAlign: "center",
                fontFamily: "Poppins, sans-serif", // Set the font-family to Poppins
                padding: "0 20px", // Adjust padding as needed
                fontSize: fontSizeMedium,
                lineHeight: lineHeight,
                zIndex: 1, // Ensure the text is on top
                position: "relative",
              }}
            >
              <h2
                style={{
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                  marginBottom: "10px",
                }}
              >
                Elevate Your Space with Color
              </h2>
              <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                Expert painting solutions to enhance your home or business
              </p>

              <Modal
                //title in center
                centered
                style={{ textAlign: "center" }}
                title="Schedule a Consultation"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
              >
                <Form name="contactForm" onFinish={onFinish}>
                  <Form.Item
                    name="name"
                    label="Your Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="Your Email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="phone"
                    label="Your Phone (optional)"
                    rules={[
                      {
                        type: "string",
                        pattern: /^[0-9]*$/,
                        message: "Please enter a valid phone number",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="message"
                    label="Your Message"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your message",
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
              <Button
                type="primary"
                size="large"
                style={{
                  marginTop: "20px",
                  backgroundColor: "#000000", // Black background color
                  color: "#ffffff", // White text color
                }}
                onClick={showModal}
                //show modal on click
              >
                Schedule a Consultation
              </Button>
            </div>
          </div>
        </Content>
        <Divider />
        <Content style={{ margin: "0 16px" }}>
          {/* Row 1 */}
          <div
            style={{
              padding: 24,
              minHeight: 100,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px", // Add margin at the bottom
            }}
          >
            <h2>About Fine Finish Painting </h2>
          </div>
          <Divider
            style={{
              color: "#333333",
              fontWeight: "bold",
              fontSize: "2rem",
              marginBottom: "10px",
            }}
          />
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
            Fine Finish Painting specializes in professional interior and
            exterior painting services, offering expert craftsmanship and
            attention to detail.
          </h2>
          {/* add diveder with text */}
          <Divider
            style={{
              color: "#333333",
              fontWeight: "bold",
              fontSize: "2rem",
              marginBottom: "10px",
            }}
          >
            Our Services
          </Divider>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={8}>
              <div
                style={{
                  padding: 24,
                  minHeight: 100,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px", // Add margin at the bottom
                }}
              >
                <h2>Interior Painting</h2>
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src={internal}
                  alt="Internal"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <div
                style={{
                  padding: 24,
                  minHeight: 100,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px", // Add margin at the bottom
                }}
              >
                <h2>Exterior Painting</h2>
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src={external}
                  alt="External"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <div
                style={{
                  padding: 24,
                  minHeight: 100,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px", // Add margin at the bottom
                }}
              >
                <h2>Residential Painting</h2>
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src={internal_2}
                  alt="Internal"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Divider
            style={{
              color: "#333333",
              fontWeight: "bold",
              fontSize: "2rem",
              marginBottom: "10px",
            }}
          />
        </Content>
        <Divider />
        <Divider
          style={{
            color: "#333333",
            fontWeight: "bold",
            fontSize: "2rem",
            marginBottom: "10px",
          }}
        >
          Interested in seeing more? Check out our gallery!
        </Divider>
        <Content style={{ margin: "0 16px" }}>
          <div>
            <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
              Masterpieces Showcase
            </h1>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div style={{ textAlign: "center" }}>
                  <Image
                    src={`data:image/png;base64,${galleryImages[currentIndex]?.Image}`}
                    alt={`Image ${currentIndex + 1}`}
                    style={{
                      width: "720px",
                      height: "480px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <Button onClick={goToPrevious}>Previous</Button>
                  <Button onClick={goToNext} style={{ marginLeft: "10px" }}>
                    Next
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Content>
        <Divider />
        <Content style={{ margin: "0 16px" }}>
          <div
            style={{
              marginTop: "50px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                marginTop: "50px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <h1 style={{ marginBottom: "20px" }}>
                Better yet, see us in person!
              </h1>
              <p>
                We stay in constant communication with our customers until the
                job is done. To get a free quote, or if you have questions or
                special requests, just drop us a line.
              </p>
            </div>
            <Row justify="center">
              <Col xs={24} sm={20} md={16} lg={12}>
                <Form name="contactForm" onFinish={onFinish}>
                  <Form.Item
                    name="name"
                    label="Your Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="Your Email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="phone"
                    label="Your Phone (optional)"
                    rules={[
                      {
                        type: "string",
                        pattern: /^[0-9]*$/,
                        message: "Please enter a valid phone number",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="message"
                    label="Your Message"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your message",
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </Content>
        <Divider />
      </Layout>
    </Layout>
  );
};

export default App;
