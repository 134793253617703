// Logout component:
import React from "react";
import { Layout, Menu } from "antd";
import { HeaderApp } from "./Header";
import AdminDashboard from "./AdminDashboard";
const { Header, Content, } = Layout;

const Logout = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/login";
    }
  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <HeaderApp />
        </div>
      </Header>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
        <Menu.Item key="1"
        onClick={() => {
            window.location.href = "/admin";
        }
        }
        >Dashboard</Menu.Item>
        <Menu.Item key="2"
        onClick={() => {
            window.location.href = "/gallery";
        }
        }
        
        >Gallery</Menu.Item>
        <Menu.Item key="3">Logout</Menu.Item>
          </Menu>
      
          <Content
              style={{
                padding: "0 48px",
              }}
          >
              <AdminDashboard />
          </Content>
      </Layout>
  );
}
export default Logout;
