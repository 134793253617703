import React, { useState, useEffect } from "react";
import {
  Image,
  Modal,
  Button,
  Upload,
  message,
  Pagination,
  Divider,
  Space,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Admin from "./Admin";
import axios from "axios";

const ImageGallery = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 6; // Number of images per page (2 rows with 3 columns each)

  useEffect(() => {
    fetchImages();
  }, [currentPage]);

  const fetchImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/gallery`
      );
      setGalleryImages(response.data || []);
      console.log("Images fetched:", response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

const handleUpload = async () => {
  const formData = new FormData();

  fileList.forEach((file, index) => {
    formData.append(`images[${index}]`, file);
  });

  try {
    const token = localStorage.getItem("authToken"); // Assuming you store the token in localStorage
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    };

    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/gallery/upload/`,
      formData,
      { headers } // Pass the headers object to the request
    );

    message.success("Files uploaded successfully");
    fetchImages();
    setUploadModalVisible(false);
    setFileList([]); // Clear file list
  } catch (error) {
    message.error("File upload failed");
    console.error("Error uploading files:", error);
  }
};
  
  const handleDelete = async (image) => {
    try {
      const token = localStorage.getItem("authToken"); // Assuming you store the token in localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      };

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/gallery/${image.id}`,
        { headers } // Pass the headers object to the request
      );

      message.success("Image deleted successfully");
      fetchImages();
    } catch (error) {
      message.error("Failed to delete image");
      console.error("Error deleting image:", error);
    }
  }


  const handleRemove = async (file) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
    try {
      const token = localStorage.getItem("authToken"); // Assuming you store the token in localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      };

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/gallery/${file.id}`,
        { headers } // Pass the headers object to the request
      );

      message.success("Image deleted successfully");
      fetchImages();
    } catch (error) {
      message.error("Failed to delete image");
      console.error("Error deleting image:", error);
    }
  };

  const uploadProps = {
    multiple: true,
    showUploadList: false,
    fileList,
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
        return false;
      }

      setFileList((prevList) => [...prevList, file]);
      return false; // Prevent default upload behavior
    },
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return galleryImages.slice(startIndex, endIndex);
  };

  const paginatedData = getPaginatedData();

  if (!Array.isArray(paginatedData)) {
    console.error("Paginated data is not an array:", paginatedData);
    // Handle the non-array case appropriately
    return;
  }

  return (
    <div>
      <Admin />
      <Divider
        orientation="left"
        style={{ color: "#333", fontWeight: "normal" }}
      />
      <Button
        type="primary"
        style={{ display: "block", margin: "auto" }}
        onClick={() => setUploadModalVisible(true)}
      >
        Upload Images
      </Button>
      <Divider />
      <Modal
        open={uploadModalVisible}
        onCancel={() => {
          setFileList([]); // Clear file list when closing the modal
          setUploadModalVisible(false);
        }}
        onOk={handleUpload}
      >
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <div style={{ marginTop: "10px" }}>
          {fileList.map((file) => (
            <div
              key={file.uid}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Image
                src={URL.createObjectURL(file)}
                alt={file.name}
                width={400}
                height={400}
                style={{
                  marginRight: "10px",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              />
              <Button
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => handleRemove(file)}
              >
                Remove
              </Button>
            </div>
          ))}
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        {getPaginatedData().map((image, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <Image
              src={`data:image/png;base64,${image.Image}`}
              alt={`Image ${index + 1}`}
              width={400}
              height={400}
              style={{
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              // size="small"
              danger
              onClick={() => handleRemove(image)}
              style={{ display: "block", margin: "auto" }}
            >
              Remove
            </Button>
          </div>
        ))}
      </div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={galleryImages.length}
        onChange={onPageChange}
        style={{ marginTop: "20px", textAlign: "center" }}
      />
    </div>
  );
};

export default ImageGallery;
