import React, { useState, useEffect } from "react";
import { Table, Space, Button, message, Modal } from "antd";
import Admin from "./Admin";
import axios from "axios";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const AdminDashboard = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null); // Add selectedRowId state
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    // Fetch data from the backend
    fetchData();
  }, []);

  const fetchData = () => {
    // Replace this URL with your actual API endpoint
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/contacts`)
      .then((res) => {
        setTableData(res.data || []);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleStatusChange = () => {
    if (!selectedRowId) return; // Check if selectedRowId is defined

    // Replace this URL with your actual API endpoint for updating the status
    axios
      .patch(`${process.env.REACT_APP_API_URL}/api/contacts/${selectedRowId}/`, {
        status: true,
      })
      .then(() => {
        message.success("Status updated successfully");
        fetchData(); // Refresh the data
        handleModalClose(); // Close the modal
      })
      .catch((error) => {
        message.error("Error updating status");
        console.error("Error updating status:", error);
      });
  };

  const handleDelete = (id) => {
    // Replace this URL with your actual API endpoint
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/contacts/${id}`)
      .then(() => {
        message.success("Contact deleted successfully");
        fetchData();
      })
      .catch((error) => {
        message.error("Error deleting contact");
        console.error("Error deleting contact:", error);
      });
  };

  const handleModalOpen = (record) => {
    setSelectedMessage(record.message);
    setSelectedRowId(record.id); // Store the ID of the selected row
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedMessage(null);
    setSelectedRowId(null); // Reset selectedRowId
    setModalVisible(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        record.status ? (
          <CheckOutlined style={{ color: "green" }} />
        ) : (
          <CloseOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => handleModalOpen(record)} // Open modal when button is clicked
          >
            View Message
          </Button>
          <Button type="primary" danger onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Admin />
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title="Message"
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
          <Button key="status" type="primary" onClick={handleStatusChange}>
            Change Status
          </Button>,
        ]}
      >
        <p>{selectedMessage}</p>
      </Modal>
    </>
  );
};

export default AdminDashboard;
